import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './JobListSection.scss';

// @ts-ignore
import { JobList } from 'career-page-components';
// import { JobList } from '../../../../../../Career Sites/career-page-components/src/index.js';

// import { JobList } from '../../../../../../Career Sites/career-page-components/src/index.js';
// import {JobList} from '../../../../../../Career Sites/career-page-components/src/index.js';

const jobListOptions = {
	jobListClassNames: 'job-list',
	searchBarWrapperClassNames: 'search-bar-wrapper',
	searchBarClassNames: 'search-bar',
	jobResultsTitleWrapperClassNames: 'results-wrapper',
	jobResultsTitleClassNames: 'search-results',
	jobListWrapperClassNames:	 'job-list-wrapper'
};

const JobListSection: FC = (props: any) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	const applyItems = [{
		text: 'Full Time',
		value: 'fullTime',
		hideLabel: true
	},{
		text: 'Req ID:',
		value: 'reqNumber',
		hideLabel: true
	}]

	console.log("Key: ",process.env.GMAPS_KEY || 'AIzaSyBFD0QXY-CaqBkfIWECnIUFGzX-KXqj58g')
	return (
		<section className={'JobListSection'}>
			<JobList
				data={data}
				options={jobListOptions}
				apiKey={process.env.GMAPS_KEY || 'AIzaSyBFD0QXY-CaqBkfIWECnIUFGzX-KXqj58g'}
				applyNowValues={applyItems}
			/>
		</section>
	);
};
export default JobListSection;
