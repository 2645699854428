import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import './SmallHero.scss';

const SmallHero: FC = (props: any) => {
	return (
		<section className={'SmallHero'}>
			<Container>
				<h1>Job Listings</h1>
			</Container>
		</section>
	);
};
export default SmallHero;
