import * as React from 'react';
import { PageProps } from 'gatsby';

import 'semantic-ui-css/semantic.min.css';
import '../../sass/main.scss';
import './index.scss';

import mestekLogo from './../../images/mestek-logo.svg';
import favicon from './../../images/favicon.png';

import Layout from '../../layouts/layout/Layout';
import { createMedia } from '@artsy/fresnel';
import SmallHero from '../../components/SmallHero/SmallHero.component';
import JobListSection from '../../components/JobListSection/JobListSection.component';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import JobAlerts from '../../components/JobAlerts/JobAlerts';

const SearchPage: React.FC<PageProps> = () => {
	const menuItems = [
		{ name: 'Home', href: '/' },
		{ name: 'Job Opportunities', href: '/search' },
		{ name: 'Benefits', href: '/benefits' }
	];
	const { MediaContextProvider, Media } = createMedia({
		breakpoints: {
			mobile: 0,
			tablet: 768,
			computer: 1200
		}
	});

	return (
		<Layout
			media={Media}
			mediaContextProvider={MediaContextProvider}
			logoSrc={mestekLogo}
			menuItems={menuItems}
			className={'search-page'}
		>
			<Helmet>
				<title>{'Mestek Careers'}</title>
				<meta
					name="description"
					content={
						'Explore job opportunities at Mestek, Inc., a family of manufacturers of HVAC equipment and automated manufacturing machinery with a legacy of innovation.'
					}
				/>
				<link
					rel="icon"
					type="image/png"
					href={favicon}
					sizes="32x32"
				/>
			</Helmet>
			<SmallHero />
			<JobListSection />
			<JobAlerts />
		</Layout>
	);
};
export default SearchPage;
